import React from "react";
import EventCard from "../components/EventCard";
import { useState } from "react";
import RegPopup from "../components/RegPopup";
import photo1 from "../Assets/ECellandIIMM_ExpertLectureFlyer.png";
import photo2 from "../Assets/E-auction.jpg";
import photo3 from "../Assets/inmoviduposter.png";
import photo4 from "../Assets/IPRWorkshop.jpeg";
import photo5 from "../Assets/Workshoponstartupfunding.jpg";
import photo6 from "../Assets/e-auction_tachyon.png";
import photo7 from "../Assets/stock_market_tachyon.png";


const upcomingEvents = [{name:"",date:"",}]
const EventsInfo = [{name:"E-Auction",description:"E-AUCTION event was organized in the college and gave students an opportunity to showcase their negotiation skills and business acumen.",photo:photo2,state:"past", month:"APR",date:"8"},
    {name:"IPR Workshop",description:"A one-day workshop “IPR Workshop on Innovation, Invention and Creativity” on 19th August 2023 .This workshop was mainly planned with the motive of spreading the knowledge on Intellectual Property Rights.",photo:photo4,state:"past", month:"AUG",date:"19"},
    {name:"Session on Business Model Cavas",description:"E Cell IIT Tirupati, in partnership with Movidu Technology Pvt Ltd, organized a guest lecture session on the Business Model Canvas by Mridula Chhetri (Founder and CEO of Movidu Technologies),which included Breakdown of pitch deck and Breakdown of Product market fit.",photo:photo3,state:"past", month:"AUG",date:"11"},
    {name:"Talk Session",description:"A session on The Art and Science of Supply Chain Management Impact on Business by Mr.T.A.B.Barathi, Senior vice president of Wheels India Ltd. The TVS group was hosted.",photo:photo1,state:"past", month:"APR",date:"24",time:""},
    {name:"Workshop on Startup Funding Ecosystem",description:"E-CELL and IIT Tirupati- Institution’s Innovation Council  jointly organized a workshop on 'Start-ups funding eco-system' on 30th August 2023 in which CEO of T-HUB and people from golden hill capital and success innovation fund were invited.",photo:photo5,state:"past", month:"AUG",date:"30"},
    {name:"E-Auction",description:"The E-Auction tests your strategic thinking, decision-making skills, and team coordination in a high-stakes simulated market environment. This exciting auction-based event challenges teams to compete for virtual companies by using a set budget efficiently and outbidding rival teams.",photo:photo6,state:"upcoming", month:"NOV",date:"16",time:'3:30 PM',link:"https://forms.fillout.com/t/8S1VSq47M4us"},
    {name:"Stock Market Essentials",description:"Want to start investing but don’t know how the stock market works? Join us for a beginner-friendly workshop where Akshat Garg, AVP at Choice Wealth Private Limited, will guide you through the essentials of stock market investing. Whether you're completely new or just want to sharpen your understanding, this workshop will give you the tools you need to get started.",photo:photo7,state:"upcoming", month:"NOV",date:"17",time:'10:30 AM',link:"https://forms.fillout.com/t/hQe3mKFwPNus"},
]

function EventsPage(props) {
    const [currentEvent,setCurrentEvent] = useState("upcoming_btn");
    const [regPopup, setRegpopup] = useState(false);
    const [eventName, setEventName] = useState("");
    const callPopup = (eventName) => {
        setRegpopup(true);
        setEventName(eventName);    

        // document.body.style.overflow = 'hidden';
    }
    const closePopup = () => {
        setRegpopup(false);
        document.body.style.overflow = 'visible';
    }
    
    const handleUpcoming = () =>{
        setCurrentEvent((prevClass)=>{
            document.querySelector("."+prevClass).classList.remove("border-b")
            document.querySelector(".upcoming_btn").classList.add("border-b")
            return "upcoming_btn"
        })
    }
    const handleongoing = () =>{
        setCurrentEvent((prevClass)=>{
            document.querySelector("."+prevClass).classList.remove("border-b")
            document.querySelector(".ongoing_btn").classList.add("border-b")
            return "ongoing_btn"
        })
    }
    const handlePast = () =>{
        setCurrentEvent((prevClass)=>{
            document.querySelector("."+prevClass).classList.remove("border-b")
            document.querySelector(".past_btn").classList.add("border-b")
            return "past_btn"
        })

    }
    var numEvents = 0;
    return (
        <>
            <div >  
                <RegPopup eventName={eventName} trigger={regPopup} closePopup={closePopup} />
                <h1 className="text-blue-500 my-20  text-5xl text-center font-extrabold">Events</h1>
                <div className=" w-full flex justify-center">
                    <ul className="flex w-fit flex-row border-b border-gray-400 border-opacity-50 [&>li]:px-3 [&>li>button]:text-blaack md:[&>li]:text-xl">
                        <li><button onClick={handleUpcoming} className="upcoming_btn border-b border-black">Upcoming Events</button></li>
                        <li><button onClick={handleongoing} className="ongoing_btn border-black">Ongoing Events</button></li>
                        <li><button onClick={handlePast} className="past_btn  border-black">Past Events</button></li>
                    </ul>
                </div>
                
                {EventsInfo.map((eventinfo,index)=>{if(eventinfo.state==currentEvent.split("_")[0]){
                    numEvents++;
                    return <EventCard callPopup={callPopup} key={index} link={eventinfo.link} month={eventinfo.month} date={eventinfo.date} time={eventinfo.time} eventName={eventinfo.name} eventInfo={eventinfo.description} img={eventinfo.photo}/>   
                }})}
                {numEvents==0 && <div className="mb-[30%] mt-12"><h1 className="text-center">No {currentEvent.split("_")[0]} events.</h1></div>}
            </div>
        </>
    );
}

export default EventsPage;