// src/AdminDashboard.js

import React from 'react';

const AdminDashboard = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>This is admin Dashbord</h1>
      
    </div>
  );
};

export default AdminDashboard;
